<template>
    <div class="freezeMeetintList_container">
        <div class="freezeMeetintList_list">
            <div class="list_title global_primary_text_color" :class="{ title_border_bottom : freezeMeetingLists .length==0}">已冻结会议（{{count_approval + count_unapproval}}）</div>
            <div class="freezeMeetintList_scroll">
                <ItemScroll ref="ItemScroll">
                    <div class="freezeMeetintList_meetItem_block" v-for="(item,index) in freezeMeetingLists" :key="index">
                        <div class="meetItem_info_block">
                            <div class="meetItem_info" style="margin-bottom: 4vw;">
                                <p class="meetItem_info_label">会议日期</p>
                                <div class="meetItem_info_content">
                                    <p>{{getDate(item.start_time)}}</p>
                                    <p>{{getTime(item.start_time)}}</p>
                                </div>
                            </div>
                            <div class="meetItem_doctor">
                                <p class="meetItem_info_label">互动专家</p>
                                <div class="meetItem_info_content">
                                    <p>{{ item.doctor_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="meetItem_status">
                            <p style="margin-bottom: 4vw;">{{item.status==3?'已取消':'已冻结'}}</p>
                            <div class="meetItem_status_button">
                                <el-button type="primary" @click="unFreezeSingleMeetTips(item)" v-if="item.status==0">申请解冻</el-button>
                                <p v-if="item.status==1" class="global_primary_text_color">解冻审批中</p>
                                <p v-if="item.status==3" style="color: #a19f9f;">解冻失败</p>
                            </div>
                        </div>
                    </div>
                </ItemScroll>
            </div>
        </div>
        <div class="freezeMeetintList_bottom_button">
          <el-button :type="count_unapproval > 0?'primary':'info'" size="small" @click="unFreezeTips">一键申请解冻</el-button>
        </div>
    </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
    data(){
        return {
            isloading:false,
            freezeMeetingLists:[],
            count_approval:0,//已申请解冻的会议数量
            count_unapproval:0//未申请解冻的会议数量
        }
    },
    components: {
        ItemScroll,
    },
    created(){
        this.getFreezMeetList();
    },
    methods:{
        getFreezMeetList(){
            let url = this.$urls.admin.freezeLists;
            this.$axios.get(url).then((res) => {
                this.freezeMeetingLists = res.data;
                this.count_approval = res.count_approval;
                this.count_unapproval = res.count_unapproval;
                this.$refs.ItemScroll.initScroll();
            });
        },
        getDate(date){
            return date.split(" ")[0];
        },
        getTime(date){
            return date.split(" ")[1];
        },
        //解冻单场会议提示
        unFreezeSingleMeetTips(item){
            let option = {
                confirmButtonText: "解冻会议",
                cancelButtonText: "取消",
                center: true,
                type: "warning",
                customClass: "freezeMeetintList_confirm_customClass",
            }
            this.$confirm(`是否解冻选择的会议`, "提示", option).then(() => {
                this.unFreeze(item);
            }).catch(() => { });
        },
        //一键解冻会议提示
        unFreezeTips(){
            let text = ""
            let option1 = {
                confirmButtonText: "一键解冻",
                cancelButtonText: "取消",
                center: true,
                type: "warning",
                customClass: "freezeMeetintList_confirm_customClass",
            }
            let option2 = {
                confirmButtonText: "确认",
                showCancelButton: false,
                center: true,
                type: "warning",
                customClass: "freezeMeetintList_confirm_customClass",
            }
            if(this.count_unapproval > 0 && this.count_approval <= 0){
                text = `是否解冻${this.count_unapproval}场会议`
                this.$confirm(`${text}`, "提示", option1).then(() => {
                    this.unFreeze();
                }).catch(() => { });
                return;
            }
            if (this.count_unapproval > 0 && this.count_approval > 0) {
                text = `当前有${this.count_approval}场会议在解冻审批中，是否申请解冻余下${this.count_unapproval}场?`
                this.$confirm(`${text}`, "提示", option1).then(() => {
                    this.unFreeze();
                }).catch(() => {});
                return;
            }
            if (this.count_unapproval <= 0 && this.count_approval > 0) {
                text = `您已申请解冻会议，请等待审批结果`;
                this.$confirm(`${text}`, "提示", option2).then(() => {}).catch(() => { });
                return;
            }
            if (this.count_unapproval <= 0 && this.count_approval <= 0) {
                text = `没有需要解冻的会议`;
                this.$confirm(`${text}`, "提示", option2).then(() => {}).catch(() => { });
                return;
            }
        },
        unFreeze(item){
            if(this.count_unapproval <= 0){
                this.$tips.error({ text: "暂无会议需解冻" });
                return;
            }
            if(this.isloading) return;
            let data = {
                ids:[]
            }
            if(item&&item.id){
                data.ids.push(item.id)
            }
            this.isloading = true;
            this.$axios.post(this.$urls.admin.unFreeze,data).then((res)=>{
                this.$tips.success({ text: "您已申请解冻会议，请等待审批结果" });
                this.isloading = false;
                this.getFreezMeetList();
            }).catch(err=>{
                this.isloading = false;
                console.log(err);
            })
        },
    }
}
</script>
<style>
.freezeMeetintList_container{
    width: 100%;
    height: 100%;
    padding: 4vw 3vw;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
}
.freezeMeetintList_container .el-button{
    border-radius: 2vw;
}
.freezeMeetintList_list{
    width: 100%;
    flex: 1 0 0;
    background-color: #fff;
    padding: 4vw;
    display: flex;
    flex-direction: column;
}
.list_title{
    padding-bottom: 2vw;
}
.title_border_bottom{
    border-bottom: 1px solid #a1a1a1;
}
.freezeMeetintList_scroll{
    flex: 1 0 0;
    overflow: hidden;
}
.freezeMeetintList_meetItem_block{
    width: 100%;
    border-top: 1px solid #d9d9d9;
    padding: 4vw 0;
    display: flex;
    color: #a19f9f;
}
.meetItem_info_block{
    flex: 1 0 0;
}
.meetItem_doctor{
    display: flex;
    height: 8vw;
}
.meetItem_info_label{
    margin-right: 4vw;
}
.meetItem_info_content{
    flex: 1 0 0;
    display: flex;
    justify-content: space-between;
}
.meetItem_info{
    display: flex;
}
.meetItem_status{
    margin-left: 2vw;
    flex: 25vw 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.meetItem_status_button{
    width: 100%;
    height: 8vw;
    text-align: right;
}
.meetItem_status_button .el-button{
    width: 100%;
    padding: 0;
    height: 100%;
    min-height: 4vw;
}
.freezeMeetintList_bottom_button{
    margin-top: 10vw;
    flex: 25vw 0 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.freezeMeetintList_confirm_customClass {
  width: 90%;
}
.freezeMeetintList_confirm_customClass .el-message-box__message,.freezeMeetintList_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
</style>